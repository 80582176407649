<template>
  <v-container>
    <v-row>
      <v-col cols="12"
             md="11">
        <h1>Welcome Job For New Customer</h1>
      </v-col>
      <v-col cols="12"
             md="1">
        <v-menu
          bottom
          left>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list rounded>
            <v-subheader>Menu</v-subheader>
            <v-list-item-group>
              <v-list-item
                v-for="(item, i) in submenu"
                :key="i"
                dense
                :to="{ name: item.link }">
                <v-list-item-icon>
                  <v-icon v-text="item.icon" />
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title v-text="item.text" />
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
    <job-list :jobWelcome="jobList" />
  </v-container>
</template>

<script>
import { mapGetters} from 'vuex';

export default {
  name: 'JobWelcomeList',
  components: {
    jobList: () => import('@/components/jobs/jobWelcomeList.vue'),
  },

  data: () => ({
    submenu: [
      { text: 'Create Welcome Job', icon: 'mdi-account-heart-outline', link: 'createWelcomeJob' },
    ],
  }),

  async created() {
    await this.$store.dispatch('jobManagement/GET_WELCOME_JOB_LIST')
  },

  computed: {
    ...mapGetters({
      jobList: 'jobManagement/GET_JOBS_WELCOME'
    })
  }
}
</script>
